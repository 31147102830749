@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica"), url("/assets/helvetica/Helvetica.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Oblique"), url("/assets/helvetica/Helvetica-Oblique.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Compressed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Compressed"), url("/assets/helvetica/helvetica-compressed-5871d14b6903a.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Rounded Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Rounded Bold"), url("/assets/helvetica/helvetica-rounded-bold-5871d05ead8de.woff") format("woff");
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica"), url("/assets/helvetica/Helvetica-Bold.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Bold Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Bold Oblique"), url("/assets/helvetica/Helvetica-BoldOblique.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Light";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Light"), url("/assets/helvetica/helvetica-light-587ebe5a59211.woff") format("woff");
}
