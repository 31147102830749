.button {
  --padding-top: 25px;
  --padding-bottom: 25px;
  --padding-start: 25px;
  --padding-end: 25px;
  --border-radius: var(--border-radius-middle);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-inline-end: auto;
  margin-inline-start: auto;

  &.ion-color-danger {
    --transition: all 0.3s ease;

    &:active {
      /* stylelint-disable-next-line declaration-no-important */
      --ion-color-base: var(--color-danger-shade) !important;
    }
  }

  &.ion-color-dark {
    --transition: all 0.3s ease;

    &:active {
      /* stylelint-disable-next-line declaration-no-important */
      --ion-color-base: var(--color-dark-shade) !important;
    }
  }

  &.ion-color-primary {
    --transition: all 0.3s ease;

    &:active {
      /* stylelint-disable-next-line declaration-no-important */
      --ion-color-base: var(--color-primary-shade) !important;
    }
  }

  &.ion-color-secondary {
    --transition: all 0.3s ease;

    &:active {
      /* stylelint-disable-next-line declaration-no-important */
      --ion-color-base: var(--color-secondary-shade) !important;
    }
  }

  .icon-history,
  .icon-logout {
    position: relative;
    top: 0.8vh;
    margin-right: 1vh;

    @media only screen and (max-width: 50em) and (orientation: portrait) {
      width: 2.8vh;
      height: 2.8vh;
      margin-right: 0.8vh;
    }
  }

  &--wrapped {
    display: inline-block;
    max-width: 100%;
    line-height: 5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    --color-text: var(--color-light);
  }
}
