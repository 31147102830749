.modal {
  --min-width: 70.8vh;
  --min-height: 64.2vh;
  --width: 70.8vh;
  --height: 64.2vh;
  text-align: center;

  .modal-wrapper {
    min-width: var(--min-width);
    min-height: var(--min-height);
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius-big);

    @media only screen and (max-width: 50em) and (orientation: portrait) {
      border-radius: 0;
    }
  }

  &--inner {
    --padding-top: 4.16vh;
    --padding-bottom: 4.16vh;
    --padding-left: 4.16vh;
    --padding-right: 4.16vh;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
    --color: var(--color-dark);
    color: var(--color-dark);
  }

  &--title {
    margin: 0;
    font-size: 4vh;
    font-weight: 700;
  }

  &--footer {
    position: absolute;
    bottom: 7vh;
    left: 0;
    right: 0;

    .button {
      display: inline-block;
      width: 100%;
      max-width: 25.6vh;
      margin-left: 1vh;
      margin-right: 1vh;
    }
  }

  &--full-screen {
    --min-width: 100vw;
    --min-height: 100vh;
    --width: 100vw;
    --height: 100vh;

    .modal-wrapper {
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 50em) and (orientation: portrait) {
    --min-width: 100%;
    --min-height: 100%;
    --width: 100%;
    --height: 100%;
  }
}
