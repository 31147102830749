:root {
  // Colors
  --color-primary: #000000;
  --color-primary-rgb: 0, 0, 0;
  --color-primary-contrast: #fafafa;
  --color-primary-contrast-rgb: 254, 254, 254;
  --color-primary-shade: #020202;
  --color-primary-shade-rgb: 10, 10, 10;
  --color-primary-tint: #020202;
  --color-primary-tint-rgb: 10, 10, 10;
  --color-secondary: #474747;
  --color-secondary-rgb: 196, 163, 109;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 33, 33, 33;
  --color-secondary-shade: #2b2b2b;
  --color-secondary-shade-rgb: 223, 187, 128;
  --color-secondary-tint: #2b2b2b;
  --color-secondary-tint-rgb: 223, 187, 128;
  --color-dark: #212121;
  --color-dark-rgb: 33, 33, 33;
  --color-dark-shade: #353535;
  --color-dark-shade-rgb: 53, 53, 53;
  --color-dark-disabled: #f7f7f7;
  --color-dark-disabled-rgb: 207, 207, 207;
  --color-light: #fafafa;
  --color-light-rgb: 250, 250, 250;
  --color-light-shade: #f7f7f7;
  --color-light-tint: #f7f7f7;
  --color-text: #474747;
  --color-text-rba: 71, 71, 71;
  --color-text-contrast: #ffffff;
  --color-text-contrast-rgb: 255, 255, 255;
  --color-text-tint: #474747;
  --color-text-disable: #cfcfcf;
  --color-background: #f2f2f2;
  --color-background-rgb: 242, 242, 242;
  --color-background-dark-contrast: #474747;
  --color-background-dark-contrast-rgb: 71, 71, 71;
  --color-background-light-contrast: #ffffff;
  --color-background-light-contrast-rgb: 255, 255, 255;
  --color-danger: #e64d69;
  --color-danger-rgb: 349, 67, 90;
  --color-danger-shade: #85184b;
  --color-danger-tint: #fcc0b9;
  --color-success: green;
  --color-success-rgb: 0, 128, 0;
  --color-border: #ebebeb;
  --color-border-rgb: 235, 235, 235;

  // Font
  --font-family: Helvetica, arial, sans-serif;
  // Border radius
  --border-radius-big: 0.5rem;
  --border-radius-middle: 0.1rem;
  --border-radius-small: 0rem;
}
