p,
h1,
h2,
h3,
h4,
h5,
span,
i {
  color: var(--color-text);
}
