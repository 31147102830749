@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");

html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-family);
  font-size: 10px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: normal;
}

input {
  border: none;
}

a,
select,
input,
button {
  &:active,
  &:focus {
    outline: 0px solid transparent;
  }
}
