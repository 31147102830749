@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.-animation-shake {
  animation-name: shake;
  animation-duration: 0.6s;
}

@keyframes rotate {
  0% {
    transform: translateY(-50%) translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(360deg);
  }
}

.veil-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(0deg);
    width: 10rem;
    height: 10rem;
    border-style: solid;
    border-width: 1rem;
    border-radius: 50%;
    border-color: var(--ion-color-primary) var(--ion-color-primary) var(--ion-color-primary) transparent;
    animation: rotate 0.75s linear infinite;
  }
}

@keyframes flash {
  from {
    opacity: 0;
    transform: rotate(-180deg);
  }
  50%,
  to {
    opacity: 1;
    transform: rotate(0deg);
  }

  25%,
  75% {
    opacity: 0;
  }
}

.-animation-flash {
  animation-name: flash;
  animation-duration: 0.6s;
}
