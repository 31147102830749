.page-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-background);
  --background: var(--color-background);

  &--background {
    background-color: var(--color-background);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.5s ease;
  }
}
