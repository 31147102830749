// http://ionicframework.com/docs/theming/
@import "./theme/variables.scss";
@import "./theme/base.scss";
@import "./theme/helvetica.scss";
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../../../shared/lib/v1/styles/index.scss";

.ocm-main {
  position: relative;
  margin-top: 92px;
  height: calc(100% - 92px);
}
